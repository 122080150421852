import React, { useEffect } from "react"
import { navigate, Link } from "gatsby"
import { isEDMode, getNested, getLocalizedURL } from '@src/helper';
// Style Imports
// import './styles.scss'
// SEO
import Seo from '@components/utility/SEO'
// Structural Imports
import Layout from "@components/structure/Layout.jsx"
// Content Imports
import Hero from "@components/content/hero/Hero.jsx"
import Image from "@components/content/fractals-image.jsx"

const RegistrationConfirmed = ({location}) => {
    useEffect(() => {
        if (!isEDMode && !getNested(location,'state', 'success')) {
            navigate( getLocalizedURL('registration','es') );
        }
    },[])

    return (
        <Layout addedClass="page--registration-confirmed">
            <Seo 
            title="Gracias | LUPKYNIS&reg; (voclosporina)" 
            description="" 
            robots="noindex" 
            canonicalURL={ "https://www.lupkynis.com" + getLocalizedURL('registration-confirmed','es') } />
            <Hero addedClass="bg-coral hero--string">
                <div className="col-wrapper">
                    <div className="container">
                        <div className="hero__content">
                            <h1 className="heading heading--accent">Registro</h1>
                            <h2>Mantenerse conectado</h2>
                            <p>Inscríbase para estar al tanto de lo más reciente sobre LUPKYNIS, obtenga información sobre cómo vivir con nefritis lúpica y escuche sobre Aurinia Alliance<sup>&reg;</sup>, un programa de apoyo personalizado.</p>
                        </div>
                        <div className="hero__image">
                            <Image data={{desktopSrc: 'registration/hero.png', mobileSrc: 'registration/hero--mobile.png'}} />
                        </div>
                    </div>
                </div>
            </Hero>
            <section className="bg-purple">
                <div className="container">
                    <h2>¡Con éxito!</h2>
                    <p>Gracias por registrarse para recibir actualizaciones de LUPKYNIS. Si optó por obtener más información sobre Aurinia Alliance<sup>&reg;</sup>, un administrador de casos del personal de enfermería se comunicará con usted en breve. Si tiene alguna pregunta sobre LUPKYNIS, hable con su médico para ver si es lo correcto para usted.</p>
                    <p>¿Desea continuar explorando?</p>
                    <Link to={getLocalizedURL('home','es')} className="button">Regresar al inicio</Link>
                </div>
            </section>
        </Layout>
    )
}

export default RegistrationConfirmed